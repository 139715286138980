<template>
	<div data-component="page-header">
		<h1
			v-if="heading"
			data-element="heading"
		>
			{{ heading }}
		</h1>
		<btn
			v-if="action"
			v-bind="action"
			size="xl"
			@actionClick="onActionClick"
		/>
	</div>
</template>

<script>

	import Btn from '@/components/ui/Btn';

	export default {
		components: {
			Btn
		},
		props: {
			heading: {
				type: String,
				default: ''
			},
			action: {
				type: Object,
				default: () => {}
			}
		},
		methods: {
			onActionClick (actionId) {
				if (!actionId) {
					return;
				}
				this.$emit('actionClick', actionId);
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='page-header'] {
		display:flex;
		align-items:center;
		margin-bottom:rem(22);
		@include cols(12);
		[data-element='heading'] {
			margin:0 auto 0 0;
			font-family:'Montserrat', sans-serif;
			font-size:rem(34);
			font-weight:700;
			color:$c-brand-blue;
		}
		[data-component='btn'] {
			margin:0 0 0 auto;
		}
	}
</style>
